/* eslint-disable no-useless-escape */
import React, { useState, useEffect } from "react";
import _ from "lodash";

const MAX_AMOUNT = "999999999999.99";
const MAX_AMOUNT_2 = "9999999999.99";

const MAX_AMOUNT_BILLS = "100000.01";
const MIN_AMOUNT_BILLS = "99.99";

const VALID_RULES = [
  "required",
  "integer",
  "number",
  "date",
  "required_if",
  "tin_number",
  "mobile_number",
  "telephone_number",
  "telephone_number_2",
  "telephone_number_3",
  "array",
  "rptax_tdn",
  "rptax_pin_api",
  "rptax_pin_csv",
  "rptax_pin_short_api",
  "rptax_pin_short_csv",
  "btax_bin",
  "amount",
  "max_bill_amount",
  "min_bill_amount",
  "capital_amount",
  "email",
  "min_six",
  "min_2_char",
  "min_3_char",
  "min_5_char",
  "min_3_digits",
  "max_10_digits",
  "max_50_char",
  "max_20_char",
  "max_30_char",
  "max_100_char",
  "text_vapt",
  "amount_150k",
  "philhealth_number",
  "between1-10",
  "mask",
  "minimum",
  "dti_no",
  "dti_no2",
  "sec_no",
  "sec_no_2",
  "sec-dti-cda_2",
  "cda_no",
  "tdn_no",
  "pin_no",
  "pin_no_19_digit",
  "tin_no",
  "tin_no_2",
  "bin_pre_registration",
  "or_no",
  "zip_code",
  "mobile_number_63",
  "mobile_number_63_req",
  "match",
  "bool_true",
  "password",
  "tdn_no",
  "rptax_tdn",
  "rptax_pin",
  "rptax_pin_short",
  "number_range"
];

const ucWords = (value) => {
  return value
    .replace("_code", "")
    .replace("_id", "")
    .replace(/_+/g, " ")
    .split(/\s/)
    .map((w) => {
      const [first, ...rest] = w;

      return `${first.toUpperCase()}${rest.join("")}`;
    })
    .join(" ");
};

export default (data = {}, init_object_rules = {}, message_settings = {}) => {
  const [response, setResponse] = useState({});
  const [object_rules, setObjectRules] = useState(init_object_rules)

  const onSetObjectRules = (newRules) => {
    setObjectRules(newRules)
  }

  useEffect(() => {
    validate()
    // eslint-disable-next-line
  }, [Object.values(data).join(','), object_rules])

  if (Array.isArray(data)) {
    throw new Error(
      `Invalid data argument type should be object, received an array`
    );
  }

  if (typeof data !== "object") {
    throw new Error(
      `Invalid data argument type should be object, received an ${typeof data}`
    );
  }

  if (Array.isArray(object_rules)) {
    throw new Error(
      `Invalid object_rules argument type should be object, received an array`
    );
  }

  if (typeof object_rules !== "object") {
    throw new Error(
      `Invalid object_rules argument type should be object, received an ${typeof object_rules}`
    );
  }

  if (Array.isArray(message_settings)) {
    throw new Error(
      `Invalid message_settings argument type should be object, received an array`
    );
  }

  if (typeof message_settings !== "object") {
    throw new Error(
      `Invalid message_settings argument type should be object, received an ${typeof message_settings}`
    );
  }

  const ruleEntries = Object.entries(object_rules);

  const validateRule = (field, rule_pattern) => {
    const fieldUCWords = ucWords(field);

    const value = data[field];

    const [rule, args] = rule_pattern.split(/:/);

    if (VALID_RULES.indexOf(rule) < 0) {
      throw new Error(`${rule} is not valid rule`);
    }

    const fieldOnMessage =
      message_settings[field] && message_settings[field][rule]
        ? message_settings[field][rule]
        : fieldUCWords;

    if (rule === "required") {
      if (value === undefined) {
        return {
          field,
          is_passed: false,
          generic_message: "Required",
          message: `${fieldOnMessage} field is Required`,
        };
      }
      if (typeof value === "string" && !value.toString().trim()) {
        return {
          field,
          is_passed: false,
          generic_message: "Required",
          message: `${fieldOnMessage} field is Required`,
        };
      }

      if (typeof value === "number" && value === "") {
        return {
          field,
          is_passed: false,
          generic_message: "Required",
          message: `${fieldOnMessage} field is Required`,
        };
      }

      if (Array.isArray(value) && value.length === 0) {
        return {
          field,
          is_passed: false,
          generic_message: "Required",
          message: `${fieldOnMessage} field is Empty`,
        };
      }
    }

    if (rule === "number") {
      if (value && !/^\d{1,}(\.\d*)?$/.test(value)) {
        return {
          field,
          is_passed: false,
          generic_message: "Must be a Number",
          message: `${fieldOnMessage} field is invalid format, should be number`,
        };
      }
    }

    if (rule === "integer") {
      if (value && !/^[0-9]{1,}?$/.test(value)) {
        return {
          field,
          is_passed: false,
          generic_message: "Must be a Number",
          message: `${fieldOnMessage} field is invalid format, should be number`,
        };
      }
    }

    if (rule === "between1-10") {
      if (+value < 1 || +value > 10) {
        return {
          field,
          is_passed: false,
          generic_message: "Must be between 1 - 10 only",
          message: `${fieldOnMessage} field is invalid format, should be number`,
        };
      }
    }

    if (rule === "email") {
      if (value && !/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value)) {
        return {
          field,
          is_passed: false,
          generic_message: "Must be a valid Email",
          message: `${fieldOnMessage} is Invalid`,
        };
      }
    }

    if (rule === "amount") {
      if (value && +value > MAX_AMOUNT) {
        return {
          field,
          is_passed: false,
          generic_message: `Invalid Amount, should be between 150,000.00 to ${parseFloat(
            MAX_AMOUNT
          ).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`,
          message: `${fieldOnMessage} field is invalid amount, should be between 150,000.00 to ${parseFloat(
            MAX_AMOUNT
          ).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`,
        };
      }
    }

    if (rule === "max_bill_amount") {
      if (value && +value >= MAX_AMOUNT_BILLS) {
        return {
          field,
          is_passed: false,
          generic_message: `Value must be less than or equal to ₱100,000`,
          message: `${fieldOnMessage}Value must be than then or equal to ₱100,000}`,
        };
      }
    }

    if (rule === "min_bill_amount") {
      if (value && +value <= MIN_AMOUNT_BILLS) {
        return {
          field,
          is_passed: false,
          generic_message: `Value must be greater than or equal to ₱100`,
          message: `${fieldOnMessage}Value must be greater then or equal to ₱100}`,
        };
      }
    }

    if (rule === "capital_amount") {
      if (value && +value > MAX_AMOUNT_2) {
        return {
          field,
          is_passed: false,
          generic_message: `Invalid Amount, should be equal or not more than ${parseFloat(
            MAX_AMOUNT
          ).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`,
          message: `${fieldOnMessage} field is invalid amount, should be equal or not more than ${parseFloat(
            MAX_AMOUNT
          ).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`,
        };
      }
    }

    if (rule === 'rptax_tdn') {
      if (value && !/^\w{2,2}-\d{5,5}-\d{5,5}$/.test(value)) {
        return {
          field,
          is_passed: false,
          generic_message: 'Invalid format, should be AA-00000-00000',
          message: `${fieldOnMessage} field is invalid format, should be AA-00000-00000`,
        };
      }
    }

    if (rule === 'rptax_pin') {
      if (
        value &&
        !/^\d{3,3}-\d{2,2}-\d{3,3}-\d{3,3}-\d{3,3}-[\w\d]{5,5}$/.test(value)
      ) {
        return {
          field,
          is_passed: false,
          generic_message: 'Invalid format, should be 000-00-000-000-000-AAAAA',
          message: `${fieldOnMessage} field is invalid format, should be 000-00-000-000-000-AAAAA`,
        };
      }
    }

    if (rule === 'rptax_pin_short') {
      if (value && !/^\d{3,3}-\d{2,2}-\d{3,3}-\d{3,3}-\d{3,3}$/.test(value)) {
        return {
          field,
          is_passed: false,
          generic_message: 'Invalid format, should be 000-00-000-000-000',
          message: `${fieldOnMessage} field is invalid format, should be 000-00-000-000-000`,
        };
      }
    }

    if (rule === "amount_150k") {
      if (value && parseFloat(value.toString().replace(/[^\d\.]/g, "")) < 150000) {
        return {
          field,
          is_passed: false,
          generic_message: `Invalid Amount, should be at least 150,000.00`,
          message: `${fieldOnMessage} field is invalid amount, should be at least 150,000.00`,
        };
      }
    }

    if (rule === "rptax_tdn") {
      if (value && !/^\w{2,2}-\d{5,5}-\d{5,5}$/.test(value)) {
        return {
          field,
          is_passed: false,
          generic_message: "Invalid format, should be AD-00000-00000",
          message: `${fieldOnMessage} field is invalid format, should be AD-00000-00000`,
        };
      }
    }

    if (rule === "philhealth_number") {
      if (
        value &&
        !/^\d{2,2}-\d{9,9}-\d{1,1}$/.test(value)
      ) {
        return {
          field,
          is_passed: false,
          generic_message: "Invalid format, should be 00-000000000-0",
          message: `${fieldOnMessage} field is invalid format, should be 00-000000000-0`,
        };
      }
    }

    // for api billing
    if (rule === "rptax_pin_api") {
      if (
        value &&
        !/^\d{3,3}-\d{2,2}-\d{3,3}-\d{3,3}-\d{3,3}-[\w\d]{5,5}$/.test(value)
      ) {
        return {
          field,
          is_passed: false,
          generic_message: "Invalid format, should be 000-00-000-000-000-AAAAA",
          message: `${fieldOnMessage} field is invalid format, should be 000-00-000-000-000-AAAAA`,
        };
      }
    }

    // for csv billing
    if (rule === "rptax_pin_csv") {
      if (
        value &&
        !/^\d{3,3}-\d{2,2}-\d{3,3}-\d{3,3}-\d{3,3}-[\w\d]{5,5}$/.test(value)
      ) {
        return {
          field,
          is_passed: false,
          generic_message: "Invalid format, should be 000-00-000-000-000-AAAAA",
          message: `${fieldOnMessage} field is invalid format, should be 000-00-000-000-000-AAAAA`,
        };
      }
    }

    if (rule === "min_six") {
      if (value && !/^.{6,}$/.test(value)) {
        return {
          field,
          is_passed: false,
          generic_message: "Invalid format, should be minimum of 6 characters",
          message: `${fieldOnMessage} field is invalid format, id length must be greater that 6 digits`,
        };
      }
    }

    if (rule === "min_3_char") {
      if (value && !/^[\w\d].{2,}$/.test(value)) {
        return {
          field,
          is_passed: false,
          generic_message: "Invalid format, should be minimum of 3 characters",
          message: `${fieldOnMessage} field is invalid format, id length must be greater than 3 characters`,
        };
      }
    }

    if (rule === "min_5_char") {
      if (value && !/^[\w-].{4,}$/.test(value)) {
        return {
          field,
          is_passed: false,
          generic_message: "Invalid format, should be minimum of 5 characters",
          message: `${fieldOnMessage} field is invalid format, id length must be greater than 5 characters`,
        };
      }
    }

    if (rule === "min_3_digits") {
      if (value && !/^[\d].{2,}$/.test(value)) {
        return {
          field,
          is_passed: false,
          generic_message: "Invalid format, should be minimum of 3 digits",
          message: `${fieldOnMessage} field is invalid format, id length must be greater than 3 digits`,
        };
      }
    }

    if (rule === "min_2_char") {
      if (value && !/^[\w\d].{1,}$/.test(value)) {
        return {
          field,
          is_passed: false,
          generic_message: "Invalid format, should be minimum of 2 characters",
          message: `${fieldOnMessage} field is invalid format, id length must be greater than 2 characters`,
        };
      }
    }

    if (rule === "max_10_digits") {
      if (value && !/^[\d].{0,9}$/.test(value)) {
        return {
          field,
          is_passed: false,
          generic_message: "Invalid format, should be max of 10 digit",
          message: `${fieldOnMessage} field is invalid format, id length must be max of 10 digits`,
        };
      }
    }

    if (rule === "max_50_char") {
      if (value && !/^[\w\d].{1,50}$/.test(value)) {
        return {
          field,
          is_passed: false,
          generic_message: "Invalid format, should be max of 50 characters",
          message: `${fieldOnMessage} field is invalid format, id length must be less than 50 digits`,
        };
      }
    }
    if (rule === "max_50_char") {
      if (value && !/^[\w\d].{1,50}$/.test(value)) {
        return {
          field,
          is_passed: false,
          generic_message: "Invalid format, should be max of 50 characters",
          message: `${fieldOnMessage} field is invalid format, id length must be less than 50 digits`,
        };
      }
    }

    if (rule === "max_20_char") {
      if (value && !/^[a-zA-Z0-9\-]{1,20}$/.test(value)) {
        return {
          field,
          is_passed: false,
          generic_message: "Invalid format, should be max of 20 characters",
          message: `${fieldOnMessage} field is invalid format, id length must be less than 20 digits`,
        };
      }
    }

    if (rule === "max_30_char") {
      if (value && !/^[\w\d].{0,29}$/.test(value)) {
        return {
          field,
          is_passed: false,
          generic_message: "Invalid format, should be max of 30 characters",
          message: `${fieldOnMessage} field is invalid format, id length must be less than 30 digits`,
        };
      }
    }

    if (rule === "max_100_char") {
      if (value && !/^[\w\d].{0,100}$/.test(value)) {
        return {
          field,
          is_passed: false,
          generic_message: "Invalid format, should be max of 100 characters",
          message: `${fieldOnMessage} field is invalid format, id length must be less than 10 characters`,
        };
      }
    }


    // for rptax api billing
    if (rule === "rptax_pin_short_api") {
      if (value && !/^\d{3,3}-\d{2,2}-\d{3,3}-\d{3,3}-\d{3,3}$/.test(value)) {
        return {
          field,
          is_passed: false,
          generic_message: "Invalid format, should be 000-00-000-000-000",
          message: `${fieldOnMessage} field is invalid format, should be 000-00-000-000-000`,
        };
      }
    }

    // for rptax csv billing
    if (rule === "rptax_pin_short_csv") {
      if (value && !/^\d{3,3}-\d{2,2}-\d{3,3}-\d{3,3}-\d{4,4}$/.test(value)) {
        return {
          field,
          is_passed: false,
          generic_message: "Invalid format, should be 000-00-000-000-0000",
          message: `${fieldOnMessage} field is invalid format, should be 000-00-000-000-0000`,
        };
      }
    }

    if (rule === "btax_bin") {
      if (value && !/^\d{3,3}-\d{2,2}-\d{4,4}-\d{7,7}$/.test(value)) {
        return {
          field,
          is_passed: false,
          generic_message: "Invalid format, should be 000-00-0000-0000000",
          message: `${fieldOnMessage} field is invalid format, should be 000-00-0000-0000000`,
        };
      }
    }

    if (rule === "text_vapt") {
      if (value && !/^[a-zA-Z.ñÑ ]*$/.test(value)) {
        return {
          field,
          is_passed: false,
          generic_message: "Invalid format",
          message: `${fieldOnMessage} field is invalid format.`,
        };
      }
    }

    if (rule === "array") {
      if (value && !Array.isArray(value)) {
        return {
          field,
          is_passed: false,
          generic_message: "Must be a List",
          message: `${fieldOnMessage} field is invalid format, should be List`,
        };
      }
    }

    if (rule === "date") {
      if (value && !/^\d{2,2}\/\d{2,2}\/\d{4,4}$/.test(value)) {
        return {
          field,
          is_passed: false,
          generic_message: "Must be a Date (MM/DD/YYYY)",
          message: `${fieldOnMessage} field is invalid format, should be MM/DD/YYYY`,
        };
      }
    }

    if (rule === 'match') {
      if (value !== data[args]) {
        return {
          field,
          is_passed: false,
          generic_message: `${args} and ${fieldOnMessage} should match`,
          message: `${args} and ${fieldOnMessage} should match`,
        };
      }
    }

    if (rule === 'password') {
      if (value && !/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$ %^&*-_]).{8,}$/.test(value)) {
        return {
          field,
          is_passed: false,
          generic_message:
            'Must have minimum of 8 characters, 1 uppercase, 1 lowercase, 1 symbol, and 1 number',
          message: `${fieldOnMessage} is Invalid`,
        };
      }
    }

    if (rule === 'bool_true') {
      if (!value) {
        return {
          field,
          is_passed: false,
          generic_message: `${fieldOnMessage} should true`,
          message: `${fieldOnMessage} should true`,
        };
      }
    }

    if (rule === "mask") {

      const [regex, mask] = args.split('~')
      const regexp = new RegExp(regex);

      if (value && !regexp.test(value)) {
        return {
          field,
          is_passed: false,
          generic_message: `Invalid format, should be ${mask}`,
          message: `${fieldOnMessage} field is invalid format, should be ${mask}`,
        };
      }
    }

    if (rule === "minimum") {

      if (parseFloat(value, 10) < parseFloat(args, 10)) {
        return {
          field,
          is_passed: false,
          generic_message: `Invalid input, atleast ${args}`,
          message: `${fieldOnMessage} field is invalid, atleast ${args}`,
        };
      }
    }

    if (rule === "required_if") {
      // format fieldconditionvalue sample field=OTHER
      const fieldConditionValue = new RegExp(/^\w+(=|>|<|!=|~){1,1}[\w\W]+$/);
      if (!fieldConditionValue.test(args)) {
        throw new Error(
          "Invalid required_if rules argument, sample target_field=value or target_field!=value"
        );
      }

      const [targetField, targetCondition, targetValue] = args.split(
        /(=|>|<|!=|~){1,1}/
      );

      const newTargetValue = targetValue === 'EMPTY' ? '' : targetValue

      const fieldValue = data[targetField];
      const fieldValueTypeIsString = typeof fieldValue === "string";
      const fieldValueTypeIsNumber = typeof fieldValue === "number";
      const targetFieldUCWords = ucWords(targetField);

      const targetFieldUCWordsOnMessage =
        message_settings[targetField] && message_settings[targetField].required
          ? message_settings[targetField].required
          : targetFieldUCWords;

      const targetValueOnMessage =
        message_settings[targetField] &&
          message_settings[targetField].required_if_value
          ? message_settings[targetField].required_if_value
          : newTargetValue;

      if (
        fieldValueTypeIsString &&
        fieldValue === newTargetValue &&
        targetCondition === "=" &&
        !value
      ) {
        return {
          field,
          is_passed: false,
          // generic_message: `Required, when ${targetFieldUCWordsOnMessage} is equal to ${targetValueOnMessage}`,
          generic_message: `Required`, // profession_other
          message: `${fieldOnMessage} field is required, when ${targetFieldUCWordsOnMessage} is equal to ${targetValueOnMessage}`,
        };
      }

      if (
        fieldValueTypeIsNumber &&
        fieldValue === +newTargetValue &&
        targetCondition === "=" &&
        !value
      ) {
        return {
          field,
          is_passed: false,
          // generic_message: `Required, when ${targetFieldUCWordsOnMessage} is equal to ${targetValueOnMessage}`,
          generic_message: `Required`, // profession_code
          message: `${fieldOnMessage} field is required, when ${targetFieldUCWordsOnMessage} is equal to ${targetValueOnMessage}`,
        };
      }

      if (
        fieldValueTypeIsString &&
        fieldValue !== newTargetValue &&
        targetCondition === "!=" &&
        !value
      ) {
        return {
          field,
          is_passed: false,
          generic_message: `Required`,
          // generic_message: `Required, when ${targetFieldUCWordsOnMessage} is not ${targetValueOnMessage}`,
          message: `${fieldOnMessage} field is required, when ${targetFieldUCWordsOnMessage} is not ${targetValueOnMessage}`,
        };
      }

      if (
        fieldValueTypeIsNumber &&
        fieldValue !== newTargetValue &&
        targetCondition === "!=" &&
        !value
      ) {
        return {
          field,
          is_passed: false,
          generic_message: `Required, when ${targetFieldUCWordsOnMessage} is not ${targetValueOnMessage}`,
          message: `${fieldOnMessage} field is required, when ${targetFieldUCWordsOnMessage} is not ${targetValueOnMessage}`,
        };
      }

      if (
        newTargetValue.split(/,/g).indexOf(fieldValue) > -1 &&
        targetCondition === "~" &&
        !value
      ) {
        return {
          field,
          is_passed: false,
          generic_message: `Required, when ${targetFieldUCWordsOnMessage} is equal to ${targetValueOnMessage}`,
          message: `${fieldOnMessage} field is required, when ${targetFieldUCWordsOnMessage} is in ${targetValueOnMessage}`,
        };
      }
    }

    if (rule === "tin_number") {
      const regex = new RegExp(/\d{3,3}-\d{3,3}-\d{3,3}-\d{5,5}/);

      if (value && !regex.test(value)) {
        return {
          field,
          is_passed: false,
          generic_message: "Invalid format, should be 000-000-000-00000",
          message: `${fieldOnMessage} field is invalid format, should be 000-000-000-00000`,
        };
      }
    }

    if (rule === "mobile_number") {
      const regex = new RegExp(/^09\d{9,9}$/);

      if (value && !regex.test(value)) {
        return {
          field,
          is_passed: false,
          generic_message: `Invalid format, should be 09000000000`,
          message: `${fieldOnMessage} field is invalid format, should be 09000000000`,
        };
      }
    }

    if (rule === "mobile_number_63") {
      const regex = new RegExp(/^639\d{9,9}$/);

      if (value && !regex.test(value)) {
        return {
          field,
          is_passed: false,
          generic_message: `Invalid format, should be 639000000000`,
          message: `${fieldOnMessage} field is invalid format, should be 639000000000`,
        };
      }
    }

    if (rule === "mobile_number_63_req") {
      const regex = new RegExp(/^63\d{10,10}$/);

      if (value && !regex.test(value)) {
        return {
          field,
          is_passed: false,
          generic_message: `Required`,
          message: `${fieldOnMessage} field is invalid format, should be 639000000000`,
        };
      }
    }

    if (rule === "telephone_number") {
      const regex = new RegExp(/^\d{7,7}$/);

      if (value && !regex.test(value)) {
        return {
          field,
          is_passed: false,
          generic_message: `Invalid format, should be 0000000`,
          message: `${fieldOnMessage} field is invalid format, should be 0000000`,
        };
      }
    }

    if (rule === "telephone_number_3") {
      const regex = new RegExp(/^\d{8,8}$/);
      if (value && !regex.test(value)) {
        return {
          field,
          is_passed: false,
          generic_message: `Invalid format, should be 00000000`,
          message: `${fieldOnMessage} field is invalid format, should be 00000000`,
        };
      }
    }

    if (rule === "telephone_number_2") {
      const regex = new RegExp(/\d{2,2}-\d{4,4}-\d{4,4}$/);

      if (value && !regex.test(value)) {
        return {
          field,
          is_passed: false,
          generic_message: `Invalid format, should be 00-0000-0000`,
          message: `${fieldOnMessage} field is invalid format, should be 00-000-000`,
        };
      }
    }

    if (rule === "tin_no") {
      const regex = new RegExp(/\d{3,3}-\d{3,3}-\d{3,3}-\d{3,3}$/);

      if (value && !regex.test(value)) {
        return {
          field,
          is_passed: false,
          generic_message: "Invalid format, should be 000-000-000-000",
          message: `${fieldOnMessage} field is invalid format, should be 000-000-000-000`,
        };
      }
    }

    if (rule === "tin_no_2") {
      const regex = new RegExp(/\d{3,3}-\d{3,3}-\d{3,3}/);

      if (value && !regex.test(value)) {
        return {
          field,
          is_passed: false,
          generic_message: "Invalid format, should be 000-000-000",
          message: `${fieldOnMessage} field is invalid format, should be 000-000-000`,
        };
      }
    }

    if (rule === "bin_pre_registration") {
      const regex = new RegExp(/\d{3,3}-\d{2,2}-\d{4,4}-\d{7,7}$/);

      if (value && !regex.test(value)) {
        return {
          field,
          is_passed: false,
          generic_message: "Invalid format, should be 000-00-0000-0000000",
          message: `${fieldOnMessage} field is invalid format, should be 000-00-0000-0000000`,
        };
      }
    }

    if (rule === "or_no") {
      const regex = new RegExp(/^[a-zA-Z]{1}[0-9]{9}$/g);

      if (value && !regex.test(value)) {
        return {
          field,
          is_passed: false,
          generic_message: `Invalid format, should be A000000000`,
          message: `${fieldOnMessage} field is invalid format, should be A000000000`,
        };
      }
    }

    if (rule === "dti_no") {
      const regex = new RegExp(/^[a-zA-Z]{2}[0-9]{9}$/g);

      if (value && !regex.test(value)) {
        return {
          field,
          is_passed: false,
          generic_message: `Invalid format, should be AA000000000`,
          message: `${fieldOnMessage} field is invalid format, should be AA000000000`,
        };
      }
    }

    // temporary
    if (rule === "dti_no2") {
      const regex = new RegExp(/^[0-9]{9}$/g);

      if (value && !regex.test(value)) {
        return {
          field,
          is_passed: false,
          generic_message: `required`,
          message: `${fieldOnMessage} required`,
        };
      }
    }

    if (rule === "sec_no") {
      const regex = new RegExp(/^[a-zA-Z]{2}[0-9]{9}$/g);

      if (value && !regex.test(value)) {
        return {
          field,
          is_passed: false,
          generic_message: `Invalid format, should be AA000000000`,
          message: `${fieldOnMessage} field is invalid format, should be AA000000000`,
        };
      }
    }

  if (rule === "sec_no_2") {
    const regex = new RegExp(/^[a-zA-Z0-9]{20}$/g);

    if (value && !regex.test(value)) {
      return {
        field,
        is_passed: false,
        generic_message: `Invalid format, should be AAAAAAA00000000`,
        message: `${fieldOnMessage} field is invalid format, should be AAAAAAA00000000`,
      };
    }
  }

  if (rule === "sec-dti-cda_2") {
    const regex = new RegExp(/^[a-zA-Z0-9]{20}$/g);

      if (value && !regex.test(value)) {
        return {
          field,
          is_passed: false,
          generic_message: `Invalid format, should be AAAAAAAAAA0000000000`,
          message: `${fieldOnMessage} field is invalid format, should be AAAAAAAAAA0000000000`,
        };
      }
    }

    if (rule === "cda_no") {
      const regex = new RegExp(/^[a-zA-Z]{2}[0-9]{9}$/g);

      if (value && !regex.test(value)) {
        return {
          field,
          is_passed: false,
          generic_message: `Invalid format, should be AA0000000000`,
          message: `${fieldOnMessage} field is invalid format, should be AA0000000000`,
        };
      }
    }

    if (rule === "tdn_no") {
      const regex = new RegExp(/\w{2,2}-\d{5,5}-\d{5,5}$/);

      if (value && !regex.test(value)) {
        return {
          field,
          is_passed: false,
          generic_message: `Invalid format, should be AA-00000-00000`,
          message: `${fieldOnMessage} field is invalid format, should be AA-00000-00000`,
        };
      }
    }

    if (rule === "pin_no") {
      const regex = new RegExp(/\d{3,3}-\d{2,2}-\d{3,3}-\d{3,3}-\d{3,3}$/);

      if (value && !regex.test(value)) {
        return {
          field,
          is_passed: false,
          generic_message: `Invalid format, should be 000-00-000-000-000`,
          message: `${fieldOnMessage} field is invalid format, should be 000-00-000-000-000`,
        };
      }
    }


    if (rule === "pin_no_19_digit") {
      const regex = new RegExp(/^\d{3,3}-\d{2,2}-\d{3,3}-\d{3,3}-\d{3,3}-[\w\d]{5,5}$/);

      if (value && !regex.test(value)) {
        return {
          field,
          is_passed: false,
          generic_message: `Invalid format, should be 000-00-000-000-000-A0000`,
          message: `${fieldOnMessage} field is invalid format, should be 000-00-000-000-000-A0000`,
        };
      }
    }

    if (rule === "zip_code") {
      const regex = new RegExp(/\d{4}$/);

      if (value && !regex.test(value)) {
        return {
          field,
          is_passed: false,
          generic_message: `Invalid format, should be 0000`,
          message: `${fieldOnMessage} field is invalid format, should be 0000`,
        };
      }
    }

    if (rule === 'number_range') {
      const [minimumNumber, , maximumNumber] = args.split(
        /(-){1,1}/
      );

      if (!/^\d{1,}(\.\d*)?$/.test(value) || (+value < minimumNumber || +value > maximumNumber)) {
        return {
          field,
          is_passed: false,
          generic_message: `Must be between ${minimumNumber} - ${maximumNumber} only`,
          message: `${fieldOnMessage} field is invalid format, should be number`,
        };
      }
    }

    return {
      field,
      is_passed: true,
    };
  };

  const process = () => {
    const validateRuleResponse = ruleEntries.map((ruleEntry) => {
      const [field, perItemArrayRules] = ruleEntry;

      const perItemArrayRulesArray = perItemArrayRules.split(/\|/g);

      return perItemArrayRulesArray.map((ruleItem) =>
        validateRule(field, ruleItem)
      );
    });

    const is_passed = validateRuleResponse
      .map((ruleResponse) => ruleResponse.every((item) => item.is_passed))
      .every((isPassed) => isPassed);

    const responseData = validateRuleResponse.reduce(
      (accumulator, currentValue, i) => {
        const checkIsPassed = currentValue
          .map((curr) => curr.is_passed)
          .every((isPassed) => isPassed);

        return {
          ...accumulator,
          [ruleEntries[i][0]]: {
            is_passed: checkIsPassed,
            generic_message: !checkIsPassed
              ? currentValue
                .filter((is_null) => is_null.generic_message)
                .map((curr) => curr.generic_message)
              : [],
            message: !checkIsPassed
              ? currentValue
                .filter((is_null) => is_null.message)
                .map((curr) => curr.message)
              : [],
          },
        };
      },
      {}
    );

    return {
      is_passed,
      data: responseData,
    };
  };

  const showFirstErrorMessage = () => {
    const responseProcess = process();

    const firstMessage = Object.entries(responseProcess.data)
      .filter((item) => !item[1].is_passed)
      .filter((x, i) => i === 0)
      .reduce((x, current) => current[1].message, true);

    return firstMessage === true ? true : firstMessage[0];
  };

  const validate = () => {
    setResponse(process());
  };

  const renderMessage = (field) => {
    // const rules = object_rules[field] || []

    // const hasRequired = rules.split(/\|/g).indexOf('required') > -1
    // const value = data[field]

    if (!_.isEmpty(response)) {
      return !response.data[field].is_passed ? (
        <small
          className='animated flash text-danger'
          style={{ fontSize: 10, padding: "1px" }}>
          {response.data[field].generic_message[0]}
        </small>
      ) : (
        <small
          className='animated fadeIn  fa fa-check text-success'
          style={{ fontSize: 10, padding: "1px" }}
        />
      );
    }

    return null;
  };

  return { response, validate, showFirstErrorMessage, renderMessage, onSetObjectRules };
};
