export const CANCEL = "API/cancel";
export const FIND_POST = "API/find_post";
export const FOUND_POST = "API/found_post";
export const GET_LIST = "API/get_list";
export const GOT_LIST = "API/got_list";
export const GET_ITEM = "API/get_item";
export const GOT_ITEM = "API/got_item";
export const CREATE_ITEM = "API/create_item";
export const CREATED_ITEM = "API/created_item";
export const UPDATE_ITEM = "API/update_item";
export const UPDATED_ITEM = "API/updated_item";
export const DELETE_ITEM = "API/delete_item";
export const DELETED_ITEM = "API/deleted_item";
export const UPLOAD_FORM_DATA = "API/upload_form_data";
export const UPLOADED_FORM_DATA = "API/uploaded_edform_data";
export const DOWNLOAD = "API/download";
export const DOWNLOADED = "API/downloaded";
export const GOT_ITEM_DELAY = "API/got_item_delay";
export const GOT_LIST_DELAY = "API/got_list_delay";
