/* eslint-disable no-unused-vars */
import React, { useContext, useState, useRef } from "react";
import { withRouter, Link } from "react-router-dom";
import _ from "lodash";
import { connect } from "react-redux";
import { ModalContext } from "App";
import Modal from "modules/common/components/Modal";
import ForgotPasswordContainerModal from "modules/auth/containers/ForgotPasswordContainerModal";
import ResetPasswordContainerModal from "modules/auth/containers/ResetPasswordContainerModal";
import PropTypes from "prop-types";
import * as actions from "../actions";
import * as c from "../constants";
import ResetPasswordMobileContainerModal from "./ResetPasswordMobileContainerModal";
import CmsContext from "../../../CmsContext";

const form = {
  pin: ""
};

function PinModalContainer({
  mobileNo,
  forgotPasswordValidate,
  resendForgotPasswordValidate,
  isLoading,
  serviceList
}) {
  const replaced =
    mobileNo.slice(0, 2) + mobileNo.slice(2).replace(/.(?=...)/g, "*");

  const [formData, setFormData] = useState(form);
  const cmsContext = useContext(CmsContext);
  const CMS_LOGOS = cmsContext.findByType("LOGOS");
  const CMS_VARIABLES = cmsContext.findByType("VARIABLES");

  const handleOnChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const setModal = useContext(ModalContext);

  const handleResendOtp = e => {
    e.preventDefault();
    resendForgotPasswordValidate({ mobile_number: mobileNo });
  };

  const handleOnSubmit = e => {
    e.preventDefault();
    forgotPasswordValidate(
      { mobile_number: mobileNo, pin: formData.pin },
      () => {
        e.preventDefault();
        setModal({
          isOpen: true,
          content: <ResetPasswordMobileContainerModal mobileNo={mobileNo} />,
          title: null,
          modalSize: "modal-lg",
          hideHeader: true,
          isCentered: true
        });
      }
    );
  };

  const handleOnClose = e => {
    e.preventDefault();
    setModal({ isOpen: false });
  };

  const handleGoBack = e => {
    e.preventDefault();
    setModal({
      isOpen: true,
      content: <ForgotPasswordContainerModal />,
      title: null,
      modalSize: "modal-lg",
      hideHeader: true,
      isCentered: true
    });
  };

  return (
    <Modal.Body className="p-0">
      <div className="forgot-container">
        <button onClick={handleGoBack} type="button" className="btn btn-link">
          <i className="fas fa-chevron-left back-login" />
        </button>
        <button
          type="button"
          className="close modal-close"
          onClick={handleOnClose}
        >
          <span aria-hidden="true">&times;</span>
        </button>
        <div className="container-fluid">
          <div className="row">
            <div className="form-section col-12 col-md-6 d-flex align-items-center">
              <div className="form-content w-100">
                <img src={_.get(CMS_LOGOS, "MAIN_LOGO.cdnUrl")} alt="" className="icon-logo" />
                <h2 className="form-title font-weight-bold mt-4 mb-3">
                  SMS Verification
                </h2>
                <p className="form-sub-title">
                  A verification sms has been sent to +{replaced}.
                </p>
                <p className="form-sub-title">
                  Enter OTP to activate your account.
                </p>
                <form onSubmit={handleOnSubmit}>
                  <div id="divOuter" className="form-group form-row">
                    <div id="divInner">
                      <input
                        id="partitioned"
                        name="pin"
                        autoFocus
                        autoComplete="off"
                        required
                        className="otp-value"
                        type="tel"
                        maxLength="6"
                        onChange={handleOnChange}
                      />
                      <div className="profile-under" />
                    </div>
                  </div>
                  <Link
                    to="/"
                    onClick={handleResendOtp}
                    className="sign-up mt-3 d-block"
                  >
                    Didn`t Receive OTP? <strong>Resend.</strong>
                  </Link>
                  <button
                    type="submit"
                    className="btn btn-primary w-100 mt-4 cms-bg-color-primary"
                    disabled={isLoading}
                  >
                    {isLoading && <i className="fa fa-spin fa-spinner" />}{" "}
                    Confirm OTP
                  </button>
                  {/* <Link
                    to='/'
                    onClick={handleResendOtp}
                    className='sign-up mt-3 d-block'>
                    Don’t have account yet? <strong>Sign up here.</strong>
                  </Link> */}
                </form>
              </div>
            </div>
            <div className="info-section col-12 col-md-6 d-flex align-items-center">
              <div className="info-container">
                <h1 className="info-title font-weight-bold text-light">
                  {_.get(CMS_VARIABLES, "ORGANIZATION")} Local Government System
                </h1>
                {serviceList &&
                  serviceList.filter(x => ['E-Government', 'E-Bills', 'E-Commerce', 'E-Services'].indexOf(_.get(x, 'attributes.name')) > -1).map(item => (
                    <div className="row mt-4">
                      <div className="col-3 pr-0">
                        <img
                          src={_.get(item, "attributes.photo")}
                          className="img-responsive"
                          alt="e-gov"
                        />
                      </div>
                      <div className="col-9 mt-4">
                        <h5 className="info-service-title">
                          {_.get(item, "attributes.name")}
                        </h5>
                        <p className="info-service-desc">
                          {_.get(item, "attributes.description")}
                        </p>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal.Body>
  );
}

PinModalContainer.propTypes = {
  mobileNo: PropTypes.string.isRequired,
  forgotPasswordValidate: PropTypes.instanceOf(Function).isRequired,
  resendForgotPasswordValidate: PropTypes.instanceOf(Function).isRequired,
  isLoading: PropTypes.bool.isRequired,
  serviceList: PropTypes.instanceOf(Array).isRequired
};


const removeENews = res => {
  const newArr = res.filter(d => {
    return d.attributes.name !== "E-News" && d.attributes.name !== "E-Wallet";
  });
  return newArr;
};


const mapStateToProps = ({ api }) => ({
  isLoading: _.get(api, "loading", []).indexOf(c.FORGOT_PASSWORD) > -1,
  serviceList: removeENews(_.get(api, "SERVICES/get_services", []).list || [])
});

const enhance = _.flowRight([withRouter, connect(mapStateToProps, actions)]);

export default enhance(PinModalContainer);
