import store from "store";
import * as req from "api/actions";
import _ from "lodash";
import md5 from 'md5';
import { Message } from 'react-chat-ui'
import * as c from "./constants";
import { fbDB, firebase } from '../../chat/Chat';


export const clearAttemp = () => req.setItem(c.ATTEMP, {});

export const attemp = (payload, callback) =>
  req.create(
    c.ATTEMP,
    "/api/v1/taxpayer/authenticate",
    payload,
    ({ res }) => {
      const { data, token } = res.data;
      if (token) {
        const profile = _.get(data, "attributes");
        localStorage.setItem("token", token);
        localStorage.setItem("profile", JSON.stringify(profile));

        const name = `${_.get(profile, 'first_name')}`
        const id = md5(`${name}${_.get(profile, 'email')}`)
        const roomId = md5(`room${name}${_.get(profile, 'email')}`)

        localStorage.setItem('chat-account', JSON.stringify({
          name,
          id,
          room: roomId
        }))

        fbDB.ref(`rooms/${roomId}`).set({
          information: {
            client: name
          },
          messages: [
            new Message({
              id: 'GoManila Helpdesk Support',
              message: `HI ${name}, How can i help you?`,
              senderName: 'GoManila Helpdesk Support',
              date_time: firebase.firestore.Timestamp.fromDate(new Date())
            })
          ]
        })

        store.dispatch(req.setItem(c.AUTH, { token, isAuthenticated: true }));
        store.dispatch(req.setItem(c.USER_PROFILE, profile));
        if (callback) callback(profile);
      }
    },
    {
      defaultMessage: "You have successfully logged in!",
    }
  );

export const login = (payload, callback) =>
  req.create(
    c.LOGIN,
    "/api/v1/taxpayer/validate_otp",
    payload,
    ({ res }) => {
      const { data, token } = res.data;
      const profile = _.get(data, "attributes");
      localStorage.setItem("token", token);
      localStorage.setItem("profile", JSON.stringify(profile));
      store.dispatch(req.setItem(c.AUTH, { token, isAuthenticated: true }));
      store.dispatch(req.setItem(c.USER_PROFILE, profile));
      if (callback) callback(profile);
    },
    {
      defaultMessage: "Authenticating...",
    }
  );

export const getProfile = (callback) =>
  req.item(c.GET_PROFILE, "/api/v1/taxpayers/profile", ({ data }) => {
    localStorage.setItem("profile", JSON.stringify(data));
    store.dispatch(req.setItem(c.USER_PROFILE, data || {}));
    if (callback) callback(data);
  });

export const updateProfile = (params, callback) =>
  req.update(
    c.UPDATE_PROFILE,
    "/api/v1/taxpayers/update/profile",
    params,
    ({ data }) => {
      localStorage.setItem("profile", JSON.stringify(data));
      store.dispatch(req.setItem(c.AUTH, { isAuthenticated: true }));
      store.dispatch(req.setItem(c.USER_PROFILE, data));
      if (callback) callback(data);
    }
  );

export const logout = (callback) =>
  req.setItemDelay(c.LOGOUT, {}, () => {
    localStorage.clear();
    store.dispatch(
      req.setItem(c.AUTH, { token: null, isAuthenticated: false })
    );
    if (callback) callback();
  });

export const register = (payload, callback, errCallback) =>
  req.create(
    c.REGISTER,
    "/api/v1/taxpayers/register",
    payload,
    callback,
    {
      defaultMessage:
        "Account successfully created, Please check your email to activate your account",
    },
    errCallback
  );

export const resendOTP = (payload, callback, errCallback) =>
  req.create(
    c.RESEND_OTP,
    "/api/v1/taxpayer/resend_otp",
    payload,
    callback,
    {
      defaultMessage: "Resent OTP",
    },
    errCallback
  );

export const forgotPassword = (payload, callback, errCallback) =>
  req.create(
    c.FORGOT_PASSWORD,
    "/api/v1/taxpayers/forgot_password",
    payload,
    callback,
    {
      defaultMessage: "Successfully Submitted, Please check your email",
    },
    errCallback
  );

export const resetPassword = (payload, callback, errCallback) =>
  req.create(
    c.RESET_PASSWORD,
    "/api/v1/taxpayers/forgot_password_change",
    payload,
    callback,
    {
      defaultMessage: "Your password successfully changed",
    },
    errCallback
  );


  export const createPassword = (payload, callback, errCallback) =>
  req.create(
    c.RESET_PASSWORD,
    "/api/v1/taxpayer/gmp_authenticate",
    payload,
    callback,
    {
      defaultMessage: "Your password successfully changed",
    },
    errCallback
  );
  
  export const createSuperAppPassword = (payload, callback, errCallback) =>
  req.create(
    c.RESET_PASSWORD,
    "/api/v1/taxpayer/super_app_authenticate",
    payload,
    callback,
    {
      defaultMessage: "Your password successfully changed",
    },
    errCallback
  );

export const activateAccount = (payload, callback) =>
  req.create(c.ACTIVATE_ACCOUNT, "/api/v1/taxpayers/verify", payload, callback);

export const updatePassword = (params, callback) =>
  req.update(
    c.UPDATE_PASSWORD,
    "/api/v1/taxpayers/change_password",
    params,
    ({ data }) => {
      if (callback) callback(data);
    }
  );

  export const updatePin = (params, callback) =>
  req.update(
    c.UPDATE_PIN,
    "/api/v1/taxpayers/change_pin",
    params,
    ({ data }) => {
      if (callback) callback(data);
    }
  );

  export const forgotPasswordValidate = (payload, callback) =>
  req.create(c.FORGOT_PASSWORD_VALIDATE, "/api/v1/taxpayers/forgot_password_validate_otp", payload, callback);

  export const resendForgotPasswordValidate = (payload, callback) =>
  req.create(c.RESEND_FORGOT_PASSWORD_VALIDATE, "/api/v1/taxpayers/resend_forgot_password_otp", payload, callback);

  export const resetPasswordWithOtp = (payload, callback, errCallback) =>
  req.create(
    c.RESET_PASSWORD_OTP,
    "/api/v1/taxpayers/forgot_password_change_otp",
    payload,
    callback,
    {
      defaultMessage: "Your password successfully changed",
    },
    errCallback
  );

  export const getRptaxApi = (callback) =>
  req.item(c.GET_RPTAX_API, "/api/v1/taxpayers/check_rptax_api", ({ data }) => {
    localStorage.setItem("rptax_api", JSON.stringify(data));
    if (callback) callback(data);
  });