import _ from "lodash";
import Alert from "react-s-alert";
import store from "../store";
import * as c from "./constants";

export const setItemDelay = (constant, data, callback) => ({
  type: c.GOT_ITEM_DELAY,
  key: constant,
  data,
  callback,
});

export const setListDelay = (constant, data, callback) => ({
  type: c.GOT_LIST_DELAY,
  key: constant,
  data,
  pager: {},
  callback,
});

export const setItem = (constant, data) => ({
  type: c.GOT_ITEM,
  key: constant,
  data,
});

export const setList = (constant, data) => ({
  type: c.GOT_LIST,
  key: constant,
  data,
  pager: {},
});

export const list = (constant, url, params, callback, cacheKey) => ({
  type: c.GET_LIST,
  key: constant,
  url,
  params,
  callback,
  cacheKey,
});

export const loadIncludedToList = (items, included) =>
  items.map((x) => {
    const rowIncluded = {};
    _.forOwn(x.relationships, (v, k) => {
      rowIncluded[k] = Array.isArray(v.data)
        ? v.data.map(
            (z) =>
              included.find(
                (y) => y.type === _.get(z, "type") && y.id === _.get(z, "id")
              ) || {}
          )
        : included.find(
            (y) =>
              y.type === _.get(v, "data.type") && y.id === _.get(v, "data.id")
          ) || {};
    });
    return { ...x, included: rowIncluded };
  });

export const toList = (constant) => (res) => ({
  type: c.GOT_LIST,
  key: constant,
  data: loadIncludedToList(
    _.get(res, "response.data") || [],
    _.get(res, "response.included") || []
  ),
  pager: _.get(res, "response.meta.pagination") || {},
  res: _.get(res, "response") || {},
});

export const listError = (constant) => (error) =>
  new Promise((resolve) => {
    store.dispatch({
      type: c.GOT_LIST,
      key: constant,
      data: [],
      pager: {},
    });
    resolve({
      type: "ON_ERROR",
      key: constant,
      error,
    });
  });

export const item = (constant, url, callback, params, cacheKey) => ({
  type: c.GET_ITEM,
  key: constant,
  url,
  callback,
  params,
  cacheKey,
});

export const findPost = (
  constant,
  url,
  payload,
  callback,
  options,
  errCallback
) => ({
  type: c.FIND_POST,
  key: constant,
  url,
  payload,
  callback,
  options,
  errCallback,
});

export const flatIncludedToItem = (x, included) => {
  const rowIncluded = {};
  _.forOwn(x.relationships, (v, k) => {
    rowIncluded[k] = Array.isArray(v.data)
      ? v.data.map(
          (z) =>
            included.find(
              (y) => y.type === _.get(z, "type") && y.id === _.get(z, "id")
            ) || {}
        )
      : included.find(
          (y) =>
            y.type === _.get(v, "data.type") && y.id === _.get(v, "data.id")
        ) || {};
  });
  return { ...x, included: rowIncluded };
};

export const toItem = (constant) => (res) => ({
  type: c.GOT_ITEM,
  key: constant,
  data: flatIncludedToItem(
    _.get(res, "response.data") || {},
    _.get(res, "response.included") || []
  ),
  res: _.get(res, "response") || {},
});

export const itemError = (constant) => (error) =>
  new Promise((resolve) => {
    store.dispatch({
      type: c.GOT_ITEM,
      key: constant,
      data: {},
    });
    resolve({
      type: "ON_ERROR",
      key: constant,
      error,
    });
  });

export const create = (
  constant,
  url,
  payload,
  callback,
  options,
  errCallback
) => ({
  type: c.CREATE_ITEM,
  key: constant,
  url,
  payload,
  callback,
  options,
  errCallback,
});

export const update = (
  constant,
  url,
  payload,
  callback,
  options,
  errCallback
) => ({
  type: c.UPDATE_ITEM,
  key: constant,
  url,
  payload,
  callback,
  options,
  errCallback,
});

export const remove = (
  constant,
  url,
  callback,
  options,
  payload,
  errCallback
) => ({
  type: c.DELETE_ITEM,
  key: constant,
  url,
  callback,
  options,
  payload,
  errCallback,
});

export const upload = (constant, url, formData, callback) => ({
  type: c.UPLOAD_FORM_DATA,
  key: constant,
  url,
  formData,
  callback,
});

export const download = (constant, url, fileName, callback) => ({
  type: c.DOWNLOAD,
  key: constant,
  url,
  fileName,
  callback,
});

export const withMessage = (doneConstant, default_message = "Success!") => ({
  response,
}) => {
  const { message, data } = response;
  if (typeof default_message !== "boolean") {
    Alert.success(_.get(data, "message") || message || default_message);
  }
  return {
    type: c.CREATED_ITEM,
    key: doneConstant,
    data: response,
  };
};
