/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { withRouter, Link, useLocation } from "react-router-dom";
import _ from "lodash";
import Alert from "react-s-alert";
import Drawer from "@material-ui/core/Drawer";
import Logo from "assets/img/logo-white.svg";
import IcnUser from "assets/img/icn-user.svg";
import LogoColored from "assets/img/logo-colored.svg";
import { get } from "session";
import { ModalContext } from "App";
import { connect } from "react-redux";
import { getRptaxApi } from "modules/auth/actions";
import { GET_RPTAX_API } from "modules/auth/constants";
import { getMridRedirectLink } from "modules/transaction/actions";
import { GET_MRID_REDIRECT_LINK } from "modules/transaction/constants";
import ConfirmAlert from "modules/common/components/ConfirmAlert";
import SelectTransactionModal from "modules/transaction/containers/SelectTransactionModal";
import SelectTransactionEhelpModal from "modules/transaction/containers/SelectTransactionEhelpModal";
import { logout } from "modules/auth/actions";
import * as transactionAction from "modules/transaction/actions";
import ComingSoon from "modules/static/ComingSoon";
import PropTypes from "prop-types";
// import Chat from "../../landing/containers/Chat2";
import { useServiceWorker } from "../../../useServiceWorker";
import SidebarDrawer from "./SidebarDrawer";
import EmptyState from "./EmptyState";
import { getServicesList } from "../../../helper";
import CmsContext from "../../../CmsContext";
import SelectEgovAndEservicesModal from "modules/transaction/smart-city/modals/SelectEgovAndEservicesModal";
import SelectInquiryModal from "modules/transaction/smart-city/modals/SelectInquiryModal";
import SmartCitySidebarDrawer from "./SmartCitySidebarDrawer";

function Header({
  history,
  profile,
  getModuleTypeList,
  isGCashLogin,
  doLogout,
  _getRptax,
  rptaxAngelesApi,
  _getMridRedirectLink,
  mridRedirectLink
}) {
  const servicesList = getServicesList();

  const [isOpenChat, setIsOpenChat] = useState(false);
  const [toTop, setToTop] = useState(false);
  const [headerBg, setHeaderBg] = useState(false);
  const [headerLogo, setHeaderLogo] = useState(LogoColored);
  const [dropdown, setDropdown] = useState(false);
  const [show, setShow] = useState(false);
  const [showSide, setShowSide] = useState(false);
  const { isUpdateAvailable, updateAssets } = useServiceWorker();
  const setModal = useContext(ModalContext);

  const cmsContext = useContext(CmsContext);
  const CMS_VARIABLES = cmsContext.findByType("VARIABLES");
  const CMS_NAVIGATION_ICONS = cmsContext.findByType("NAVIGATION_ICONS");
  const CMS_LOGOS = cmsContext.findByType("LOGOS");
  const CMS_COMPONENTS = cmsContext.findByType("COMPONENTS");

  const hasEmptyProfileValue = _.values(
    _.pick(profile, [
      "first_name",
      "last_name",
      "photo",
      "birthdate",
      "place_of_birth_region_code",
      "place_of_birth_province_code",
      "place_of_birth_municipality_code"

      // "gender",
      // "region_code",
      // "province_code",
      // "municipality_code",
      // "province_code",
      // "street",

      // "birth_date",
      // "civil_status",
      // "mother_maiden_last_name",
      // "mother_maiden_first_name",
    ])
  ).some(value => _.isEmpty(value));

  const handleToggleDropdown = e => {
    e.preventDefault();
    setDropdown(true);
  };

  // const handleOnToggle = (value) => (e) => {
  //   e.preventDefault();
  //   setShow(!value);
  // };
  const showModal = () => {
    if (_.get(CMS_COMPONENTS, "SMART_CITY_HEADER") === false || _.get(CMS_COMPONENTS, "SMART_CITY_HEADER") === undefined) {
      return <SelectTransactionModal />
    }
    if (_.get(CMS_COMPONENTS, "SMART_CITY_HEADER")) {
      return <SelectEgovAndEservicesModal />
    }
    // return '';
  }

  const handleShowSide = value => e => {
    e.preventDefault();
    if (isGCashLogin) {
      return history.push("/gcash");
    }

    return setShowSide(!value);
  };

  const redirect = link => e => {
    e.preventDefault();
    setShowSide(false);
    return history.push(link);
  };

  const handleOpenTransactionModal = () => {
    getModuleTypeList();
    setModal({
      isOpen: true,
      content: _.get(CMS_COMPONENTS, "E_GOVERNMENT_COMING_SOON") ? (
        <ComingSoon />
      ) : (
        showModal()
      ),
      title: null,
      modalSize: "select-transaction-modal",
      hideHeader: true,
      isCentered: true
    });
    setShowSide(false);
  };

  const handleEhelpModal = e => {
    e.preventDefault();
    setModal({
      isOpen: true,
      content: <SelectTransactionEhelpModal />,
      title: null,
      modalSize: "modal-md",
      hideHeader: true,
      isCentered: true
    });
    setShowSide(false);
  };

  const handleInquiryModal = e => {
    e.preventDefault();
    setModal({
      isOpen: true,
      content: <SelectInquiryModal />,
      title: null,
      modalSize: "modal-md",
      hideHeader: true,
      isCentered: true
    });
    setShowSide(false);
  };

  const handleClickBody = e => {
    try {
      if (
        e.target.className.indexOf("img-small-nav") < 0 &&
        e.target.className.indexOf("dropdown-toggle") < 0
      ) {
        setDropdown(false);
      }
    } catch (error) {
      // do nothing...
    }
  };

  const handleOnLogOut = e => {
    e.preventDefault();
    setShowSide(false);
    ConfirmAlert(
      "Are you sure you want to logout?",
      () => {
        doLogout(() => {
          window.location.href = "/logout";
        });
        // history.push("/logout");
      },
      () => { },
      {
        yesLabel: "Log out",
        noLabel: "Cancel"
      }
    );
  };

  useEffect(() => {
    document.body.addEventListener("click", handleClickBody);
    return () => {
      document.body.removeEventListener("click", handleClickBody);
    };
  }, []);

  const onUpdateAssets = e => {
    e.preventDefault();
    updateAssets();
  };

  const handleScroll = () => {
    const topSafari = document.body.scrollTop;
    const topChrom = document.documentElement.scrollTop;
    if (topChrom > 60 || topSafari > 60) {
      setHeaderBg(true);
      setHeaderLogo(Logo);
      setToTop(true);
      return;
    }
    setHeaderLogo(LogoColored);
    setHeaderBg(false);
    setToTop(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    window.addEventListener("scroll", handleScroll);
    // eslint-disable-next-line consistent-return
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleSelectTransaction = e => {
    e.preventDefault();
    if (hasEmptyProfileValue) {
      Alert.info(
        "Please complete you profile information, This will help you to speed up the completeness of your information in every transaction you initiate"
      );
      setShowSide(false);
      return history.push("/profile-edit");
    }

    return handleOpenTransactionModal(e);
  };
  // eslint-disable-next-line consistent-return
  const handleSelectBills = e => {
    e.preventDefault();
    setShowSide(false);
    return history.push("/e-business");
    // }
  };

  const renderIsActive = route => {
    const location = useLocation();
    if (location.pathname === route) {
      return "isCurrentPage";
    }
    return false;
  };

  const handleChat = () => {
    // setModal({
    //   isOpen: true,
    //   content: <Chat />,
    //   title: "Chat with Us!",
    //   modalSize: "modal-md",
    //   hideHeader: true,
    //   isCentered: true,
    // });
    document.body.classList.add("has-chat");
    setIsOpenChat(true);
  };

  const onCloseChat = e => {
    e.preventDefault();
    document.body.classList.remove("has-chat");
    setIsOpenChat(false);
  };

  useEffect(() => {
    if (_.get(CMS_COMPONENTS, "RPTAX") === "ANGELES") {
      _getRptax();
    }
  }, []);

  const handleMridRedirectLink = e => {
    e.preventDefault();

    _getMridRedirectLink({}, response => {
      if (!_.isEmpty(response)) {
        setTimeout(() => {
          window.open(_.get(response, "res.data.redirect_url"));
        }, 100);
      }
      return "";
    });
  };

  return (
    <>
      <div className="d-print-none header-section u-header u-header--modern u-header--bordered u-header--bg-transparent u-header--white-nav-links cms-outline-primary">
        {isUpdateAvailable && (
          <div
            style={{
              fontSize: "12px",
              height: "30px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "fixed",
              width: "100vw",
              zIndex: "1000"
            }}
            className="alert alert-danger text-center m-0"
            role="alert"
          >
            {_.get(CMS_VARIABLES, "TAG_LINE")}! New updates available &nbsp;
            <a
              href="#update"
              className="text-danger tex-weight-bold"
              onClick={onUpdateAssets}
            >
              Here!
            </a>
          </div>
        )}
        <nav className="navbar navbar-expand-lg w-100">
          <div className="logo-cont-wrapper" style={{ maxWidth: "2590px" }}>
            {!isGCashLogin && (
              <button
                className="navbar-toggler m-0 p-0 mt-2"
                type="button"
                onClick={handleShowSide(showSide)}
              >
                <i className="navbar-toggler-icon fas fa-bars font-weight-bold" />
              </button>
            )}
            {hasEmptyProfileValue ? (
              <div className="logo-cont-sm px-2 flex-grow-1 text-center">
                <img
                  src={_.get(CMS_LOGOS, "MAIN_LOGO.cdnUrl")}
                  alt=""
                  className="icon-logo"
                />
              </div>
            ) : (
              <Link
                className="logo-cont-sm px-2 flex-grow-1 text-center"
                to="/"
              >
                <img
                  src={_.get(CMS_LOGOS, "MAIN_LOGO.cdnUrl")}
                  alt=""
                  className="icon-logo"
                />
              </Link>
            )}
            <div className="nav-icons-wrapper">
              {!hasEmptyProfileValue && (
                <Link className={`${renderIsActive("/")} nav-icons`} to="/">
                  <img
                    src={_.get(CMS_NAVIGATION_ICONS, "HOME.cdnUrl")}
                    alt="Home"
                  />
                  <p>Home</p>
                </Link>
              )}
              {servicesList && servicesList.length === 0 && (
                <div className="col-12">
                  <EmptyState message="Soon to be available" />
                </div>
              )}
              {servicesList &&
                servicesList.length > 0 &&
                servicesList.map(item => (
                  <React.Fragment key={item.id}>
                    {(_.get(CMS_COMPONENTS, "SMART_CITY_HEADER") === false || _.get(CMS_COMPONENTS, "SMART_CITY_HEADER") === undefined) && (
                      (_.get(item, "attributes.name") === "E-MRID" && _.get(profile, 'is_resident') === 1) && (
                        <button
                          className="btn nav-icons btn-nav-icons"
                          type="button"
                          onClick={handleMridRedirectLink}
                        >
                          <img
                            src={_.get(CMS_NAVIGATION_ICONS, "E-MRID.cdnUrl")}
                            alt="E-MRID"
                          />
                          <p>{_.get(item, "attributes.name")}</p>
                        </button>
                      )
                    )}
                    {(_.get(CMS_COMPONENTS, "SMART_CITY_HEADER") === false || _.get(CMS_COMPONENTS, "SMART_CITY_HEADER") === undefined) && (
                      _.get(item, "attributes.name") === "E-Government" &&
                      _.get(CMS_COMPONENTS, "RPTAX") === "ANGELES" &&
                      rptaxAngelesApi.is_available === true && (
                        <button
                          type="button"
                          className={`${renderIsActive(
                            "/e-government"
                          )} btn nav-icons btn-nav-icons`}
                          onClick={handleSelectTransaction}
                        >
                          <img
                            src={_.get(
                              CMS_NAVIGATION_ICONS,
                              "E-GOVERNMENT.cdnUrl"
                            )}
                            alt="E-Government"
                          />
                          <p>{_.get(item, "attributes.name")}</p>
                        </button>
                      )
                    )}
                    {(_.get(CMS_COMPONENTS, "SMART_CITY_HEADER") === false || _.get(CMS_COMPONENTS, "SMART_CITY_HEADER") === undefined) && (
                      _.get(item, "attributes.name") === "E-Government" &&
                      _.get(CMS_COMPONENTS, "RPTAX") !== "ANGELES" && (
                        <button
                          type="button"
                          className={`${renderIsActive(
                            "/e-government"
                          )} btn nav-icons btn-nav-icons`}
                          onClick={handleSelectTransaction}
                        >
                          <img
                            src={_.get(
                              CMS_NAVIGATION_ICONS,
                              "E-GOVERNMENT.cdnUrl"
                            )}
                            alt="E-Government"
                          />
                          <p>{_.get(item, "attributes.name")}</p>
                        </button>
                      )
                    )}
                    {(_.get(CMS_COMPONENTS, "SMART_CITY_HEADER") === false || _.get(CMS_COMPONENTS, "SMART_CITY_HEADER") === undefined) && (
                      _.get(item, "attributes.name") === "E-Commerce" && (
                        <Link
                          className={`${renderIsActive(
                            "/e-commerce"
                          )}  nav-icons `}
                          to="/e-commerce"
                        >
                          <img
                            src={_.get(CMS_NAVIGATION_ICONS, "E-COMMERCE.cdnUrl")}
                            alt="E-Commerce"
                          />
                          <p>{_.get(item, "attributes.name")}</p>
                        </Link>
                      )
                    )}
                    {(_.get(CMS_COMPONENTS, "SMART_CITY_HEADER") === false || _.get(CMS_COMPONENTS, "SMART_CITY_HEADER") === undefined) && (
                      _.get(item, "attributes.name") === "E-Bills" && (
                        <button
                          type="button"
                          className={`${renderIsActive(
                            "/e-business"
                          )} btn nav-icons btn-nav-icons`}
                          onClick={handleSelectBills}
                        >
                          <img
                            src={_.get(CMS_NAVIGATION_ICONS, "E-BILLS.cdnUrl")}
                            alt="E-Business"
                          />
                          <p>{_.get(item, "attributes.name")}</p>
                        </button>
                      )
                    )}
                    {(_.get(CMS_COMPONENTS, "SMART_CITY_HEADER") === false || _.get(CMS_COMPONENTS, "SMART_CITY_HEADER") === undefined) && (
                      _.get(item, "attributes.name") === "E-Services" && (
                        <Link
                          className={`${renderIsActive(
                            "/e-services"
                          )}  nav-icons `}
                          to="/e-services"
                        >
                          <img
                            src={_.get(CMS_NAVIGATION_ICONS, "E-SERVICES.cdnUrl")}
                            alt="E-Services"
                          />
                          <p>{_.get(item, "attributes.name")}</p>
                        </Link>
                      )
                    )}
                    {(_.get(CMS_COMPONENTS, "SMART_CITY_HEADER") === false || _.get(CMS_COMPONENTS, "SMART_CITY_HEADER") === undefined) && (
                      _.get(item, "attributes.name") === "E-Wallet" && (
                        <Link
                          className={`${renderIsActive(
                            "/e-wallet/cash-in"
                          )}  nav-icons `}
                          to="/e-wallet/cash-in"
                        >
                          <img
                            src={_.get(CMS_NAVIGATION_ICONS, "E-WALLET.cdnUrl")}
                            alt="E-Wallet"
                          />
                          <p>{_.get(item, "attributes.name")}</p>
                        </Link>
                      )
                    )}
                    {(_.get(CMS_COMPONENTS, "SMART_CITY_HEADER") === false || _.get(CMS_COMPONENTS, "SMART_CITY_HEADER") === undefined) && (
                      _.get(item, "attributes.name") === "E-News" && (
                        <Link
                          className={`${renderIsActive("/news")}  nav-icons `}
                          to="/news"
                        >
                          <img
                            src={_.get(CMS_NAVIGATION_ICONS, "E-NEWS.cdnUrl")}
                            alt="E-News"
                          />
                          <p>{_.get(item, "attributes.name")}</p>
                        </Link>
                      )
                    )}
                    {(_.get(CMS_COMPONENTS, "SMART_CITY_HEADER") === false || _.get(CMS_COMPONENTS, "SMART_CITY_HEADER") === undefined) && (
                      _.get(item, "attributes.name") === "E-Help" && (
                        <button
                          type="button"
                          className={`${renderIsActive(
                            "/e-help"
                          )} btn nav-icons btn-nav-icons`}
                          onClick={handleEhelpModal}
                        >
                          <img
                            src={_.get(CMS_NAVIGATION_ICONS, "E-HELP.cdnUrl")}
                            alt="E-Help"
                          />
                          <p>{_.get(item, "attributes.name")}</p>
                        </button>
                      )
                    )}
                    {_.get(CMS_COMPONENTS, "SMART_CITY_HEADER") === true && (
                      _.get(item, "attributes.name") === "E-Government and E-Services" &&
                      (
                        <button
                          type="button"
                          className={`${renderIsActive(
                            "/e-government-and-services"
                          )} btn nav-icons btn-nav-icons`}
                          onClick={handleSelectTransaction}
                        >
                          <img
                            src={_.get(
                              CMS_NAVIGATION_ICONS,
                              "E-GOVERNMENT-AND-SERVICES.cdnUrl"
                            )}
                            alt="E-Government and Services"
                          />
                          <p>{_.get(item, "attributes.name")}</p>
                        </button>
                      )
                    )}
                    {_.get(CMS_COMPONENTS, "SMART_CITY_HEADER") === true && (
                      _.get(item, "attributes.name") === "E-Inquiry" && (
                        <button
                          type="button"
                          className={`${renderIsActive(
                            "/e-inquiry"
                          )} btn nav-icons btn-nav-icons`}
                          onClick={handleInquiryModal}
                        >
                          <img
                            src={_.get(CMS_NAVIGATION_ICONS, "E-INQUIRY.cdnUrl")}
                            alt="E-Inquiry"
                          />
                          <p>{_.get(item, "attributes.name")}</p>
                        </button>
                      )
                    )}
                    {_.get(CMS_COMPONENTS, "SMART_CITY_HEADER") === true && (
                      _.get(item, "attributes.name") === "E-News" && (
                        <Link
                          className={`${renderIsActive("/news")}  nav-icons `}
                          to="/news"
                        >
                          <img
                            src={_.get(CMS_NAVIGATION_ICONS, "E-NEWS.cdnUrl")}
                            alt="E-News"
                          />
                          <p>{_.get(item, "attributes.name")}</p>
                        </Link>
                      )
                    )}
                  </React.Fragment>
                ))}
            </div>
            {/* <button
              className='navbar-toggler'
              type='button'
              onClick={handleOnToggle(show)}>
              <i className='navbar-toggler-icon fas fa-bars font-weight-bold' />
            </button> */}
            <div
              className={`collapse navbar-collapse ${show ? "show" : ""}`}
              id="navbarNav"
            >
              <ul className="navbar-nav ml-auto">
                <li className="nav-item d-block d-sm-none">
                  <button
                    type="button"
                    onClick={handleOnLogOut}
                    className="dropdown-item text-dark my-3 px-2"
                  >
                    Logout
                  </button>
                </li>
                <li className="nav-item dropdown d-none d-md-block">
                  <a
                    href="/"
                    className="nav-link nav-menu dropdown-toggle"
                    onClick={handleToggleDropdown}
                  >
                    <img
                      width="40"
                      height="40"
                      className="img img-small-nav rounded-pill mr-2"
                      src={_.get(profile, "photo") || IcnUser}
                      alt={_.get(profile, "_full_name")}
                    />
                    <div
                      className={`profile-dropdown dropdown-menu header-dropdown dropdown-menu-right ${dropdown ? "show" : ""
                        }`}
                      aria-labelledby="dropdownMenuButton"
                    >
                      <button
                        type="button"
                        onClick={() => {
                          history.push("/profile-edit");
                        }}
                        className="dropdown-item px-3"
                      >
                        Edit Profile
                      </button>
                      <button
                        type="button"
                        onClick={handleOnLogOut}
                        className="dropdown-item px-3"
                      >
                        Logout
                      </button>
                    </div>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
        {toTop ? (
          <div className="scrollToTop-inner" style={{ zIndex: 999 }}>
            <button
              className="btn-to-top"
              onClick={e => {
                e.preventDefault();
                window.scrollTo({
                  top: 0,
                  left: 0,
                  behavior: "smooth"
                });
              }}
              type="button"
            />
          </div>
        ) : (
          ""
        )}
        <Drawer
          anchor="left"
          open={showSide}
          onClose={event => {
            if (
              event.type === "keydown" &&
              (event.key === "Tab" || event.key === "Shift")
            ) {
              return;
            }
            setShowSide(false);
          }}
        >
          {(_.get(CMS_COMPONENTS, "SMART_CITY_HEADER") === false || _.get(CMS_COMPONENTS, "SMART_CITY_HEADER") === undefined) && (
            <SidebarDrawer
              handleRedirect={redirect}
              handleTransact={handleSelectTransaction}
              handleButtonBills={handleSelectBills}
              handleEhelpModal={handleEhelpModal}
              profile={profile}
              onLogOut={handleOnLogOut}
              mridRedirectLink={mridRedirectLink}
            >
              Menu content here
            </SidebarDrawer>
          )}
          {_.get(CMS_COMPONENTS, "SMART_CITY_HEADER") === true && (
            <SmartCitySidebarDrawer
              handleRedirect={redirect}
              handleTransact={handleSelectTransaction}
              handleInquiry={handleInquiryModal}
              handleButtonBills={handleSelectBills}
              handleEhelpModal={handleEhelpModal}
              profile={profile}
              onLogOut={handleOnLogOut}
              mridRedirectLink={mridRedirectLink}
            >
              Menu content here
            </SmartCitySidebarDrawer>
          )}
        </Drawer>
        {/* {isOpenChat && (
          <div className='chat-room'>
            <Chat onClose={onCloseChat} />
          </div>
        )}
        {!isOpenChat && (
          <div className='chat-wrapper'>
            <button
              className='chat-img'
              onClick={(e) => {
                e.preventDefault();
                handleChat();
              }}
              type='button'
            />
          </div>
        )} */}
      </div>
    </>
  );
}

Header.defaultProps = {
  rptaxAngelesApi: {}
};
Header.propTypes = {
  history: PropTypes.instanceOf(Object).isRequired,
  profile: PropTypes.instanceOf(Object).isRequired,
  getModuleTypeList: PropTypes.instanceOf(Function).isRequired,
  isGCashLogin: PropTypes.bool.isRequired,
  doLogout: PropTypes.instanceOf(Function).isRequired,
  _getRptax: PropTypes.instanceOf(Function).isRequired,
  rptaxAngelesApi: PropTypes.instanceOf(Object),
  _getMridRedirectLink: PropTypes.instanceOf(Function).isRequired,
  mridRedirectLink: PropTypes.instanceOf(Object).isRequired
};

const mapStateToProps = ({ api }) => ({
  profile: _.get(JSON.parse(get("profile")), "attributes") || {},
  isGCashLogin: !!_.get(
    JSON.parse(get("profile")),
    "attributes.is_gcash_login"
  ),
  rptaxAngelesApi: _.get(api, `${GET_RPTAX_API}`, {}).item || {},
  mridRedirectLink: _.get(api, `${GET_MRID_REDIRECT_LINK}`, {}).item || {}
});

const enhance = _.flowRight([
  withRouter,
  connect(mapStateToProps, {
    ...transactionAction,
    doLogout: logout,
    _getRptax: getRptaxApi,
    _getMridRedirectLink: getMridRedirectLink
  })
]);

export default enhance(Header);
