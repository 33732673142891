export const GET_MODULE_TYPE_LIST = "TRANSACTION/get_module_type_list";
export const GET_ORGANIZATION_TYPE = "TRANSACTION/GET_ORGANIZATION_TYPE";
export const GET_BUSINESS_LINE_LIST = "TRANSACTION/GET_BUSINESS_LINE_LIST";
export const GET_BUSINESS_NATURE_LIST = "TRANSACTION/GET_BUSINESS_NATURE_LIST";
export const GET_SELECTED_MODULE_TRANSACTION_REQUIREMENTS =
  "TRANSACTION/get_selected_module_transaction_requirements";
export const GET_SELECTED_MODULE_SYSTEM =
  "TRANSACTION/get_selected_module_system";
export const GET_CITIZENSHIP = "TRANSACTION/get_citizenship";
export const GENERATE_PGI = "TRANSACTION/GENERATE_PGI";
export const GET_BILLINGS_IMPORT = "TRANSACTION/GET_BILLINGS_IMPORT";
export const GET_BPLS_BILLINGS_IMPORT = "TRANSACTION/GET_BPLS_BILLINGS_IMPORT";
export const GET_MRID_REDIRECT_LINK = "TRANSACTION/GET_MRID_REDIRECT_LINK";
