// eslint-disabled
import React, { memo } from "react";
// import React, { memo, useState } from "react";
import Countdown from "react-countdown";
import PropTypes from "prop-types";

const Completionist = () => (
  <span>
    Your OTP is <b>EXPIRED</b>
  </span>
);

const Renderer = ({ minutes, seconds, completed }) => {
  if (completed) {
    return <Completionist />;
  }
  return (
    <span>
      OTP Will be expired in{" "}
      <span className='font-weight-bold'>
        ({minutes}:{`0${seconds}`.slice(-2)})
      </span>
    </span>
  );
};


Renderer.defaultProps = {
  minutes: '', seconds: '', completed: false
}

Renderer.propTypes = {
  minutes: PropTypes.string, seconds: PropTypes.string, completed: PropTypes.bool
}

function CountDownComponent({ key }) {
  // const [isComplete, setComplete] = useState(false);

  // const handleComplete = () => {
  //   // setComplete(true);
  //   onComplete();
  // };
  // Random component
  

  // Renderer callback with condition
  
  return (
    <div>
      <Countdown
        key={key}
        // onComplete={handleComplete}
        date={Date.now() + 300000}
        renderer={Renderer}
      />
    </div>
  );
}

CountDownComponent.propTypes = {
  // onComplete: PropTypes.instanceOf(Function).isRequired,
  key: PropTypes.string.isRequired,
};

export default memo(CountDownComponent);
