import * as req from "api/actions";
import _ from "lodash";
import * as c from "./constants";

export const getModuleTypeList = (params, callback) =>
  req.list(
    c.GET_MODULE_TYPE_LIST,
    "/api/v1/taxpayers/module_systems",
    params,
    callback
  );

export const getOrganizationType = () =>
  req.list(c.GET_ORGANIZATION_TYPE, "/api/v1/taxpayers/organization_types");

export const getBusinessLineList = params =>
  req.list(
    c.GET_BUSINESS_LINE_LIST,
    "/api/v1/taxpayers/line_businesses",
    params
  );

export const getBusinessNatureList = (id, params) =>
  req.list(
    c.GET_BUSINESS_NATURE_LIST,
    `/api/v1/taxpayers/line_businesses/${id}/nature_businesses`,
    params
  );

export const getSelectedModuleTransactionRequirements = id =>
  req.list(
    c.GET_SELECTED_MODULE_TRANSACTION_REQUIREMENTS,
    `/api/v1/taxpayers/application_statuses/${id}/requirements`
  );

export const getSelectedModuleSystem = id =>
  req.item(
    c.GET_SELECTED_MODULE_SYSTEM,
    `/api/v1/taxpayers/module_systems/${id}`
  );

export const generatePGI = (id, payload, callback) =>
  req.create(
    c.GENERATE_PGI,
    `/api/v1/taxpayers/paymentgate/${id}`,
    payload,
    ({ data }) => {
      const url = _.get(data, "attributes.url");
      if (callback) callback(url);
    },
    {
      defaultMessage: "Redirecting to Payment Gateway..."
    }
  );

export const getCitizenship = () =>
  req.list(c.GET_CITIZENSHIP, "/api/v1/taxpayers/citizenships");

export const getBillingsImport = (params, callback) =>
  req.item(
    c.GET_BILLINGS_IMPORT,
    `/api/v1/taxpayers/billing_import`,
    callback,
    params
  );

export const getBPLSBillingsImport = (params, callback) =>
  req.create(
    c.GET_BPLS_BILLINGS_IMPORT,
    `/api/temp/taxpayers/business_tax/create_billing`,
    callback,
    params
  );

export const getMridRedirectLink = (params, callback) =>
  req.item(
    c.GET_MRID_REDIRECT_LINK,
    `/api/v1/taxpayers/mrid_redirect`,
    callback,
    params
  );
