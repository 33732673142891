/* eslint-disable consistent-return */
import React, { useContext, useState, useEffect } from "react";
import { withRouter, Link } from "react-router-dom";
import _ from "lodash";
import { connect } from "react-redux";
import Alert from "react-s-alert";
import { ModalContext } from "App";
import FormInputMobile from "modules/common/components/FormInputMobile";
import Modal from "modules/common/components/Modal";
import eyeOpen from "assets/img/open-eye.png";
import eyeClose from "assets/img/close-eye.png";
import SignUpContainerModal from "modules/auth/containers/SignUpContainerModal";
import PropTypes from "prop-types";
import useFormValidator from 'useFormValidator';
import LoginPinInput from "modules/common/components/LoginPinInput";
import * as actions from "../actions";
import * as c from "../constants";
import ForgotPasswordContainerModal from "./ForgotPasswordContainerModal";
import CmsContext from "../../../CmsContext";

const form = {
  password: "",
  profile_type: "taxpayer",
  otp: "",
  login_type: "credentials",
  mobile_number: "",
  disableResendOTP: false,
  login_pin: "",
  user_id: "",
};

function LoginContainer({
  clearAttemp,
  attemp,
  attempData,
  attempting,
  // history,
  serviceList,
}) {
  const cmsContext = useContext(CmsContext);
  const CMS_LOGOS = cmsContext.findByType("LOGOS");
  const CMS_VARIABLES = cmsContext.findByType("VARIABLES");
  const CMS_COMPONENTS = cmsContext.findByType("COMPONENTS");

  const [formData, setFormData] = useState(form);
  const [view, setView] = React.useState(false);
  const setModal = useContext(ModalContext);
  const [isEmail, setIsEmail] = useState("mobile");

  const formValidator = useFormValidator(formData, {
    mobile_number: 'required',
    password: 'required'
  })

  const formValidatorUserId = useFormValidator(formData, {
    user_id: 'required',
    login_pin: 'required'
  })

  useEffect(() => {
    if (isEmail === 'email') {
      formValidator.onSetObjectRules({
        mobile_number: 'required|email',
        password: "required"
      })
    }
    if (isEmail === 'mobile') {
      formValidator.onSetObjectRules({
        mobile_number: 'required|mobile_number_63',
        password: "required"
      })
    }
    if (isEmail === 'user_id') {
      formValidatorUserId.onSetObjectRules({
        user_id: 'required',
        login_pin: "required"
      })
    }
  }, [isEmail])

  const handleOnViewPassword = value => e => {
    e.preventDefault();
    setView(value);
  };

  const handleOpenAuthModal = e => {
    e.preventDefault();
    setModal({
      isOpen: true,
      content: <SignUpContainerModal />,
      title: null,
      modalSize: "modal-lg",
      hideHeader: true,
      isCentered: true
    });
  };

  const handleOnSubmit = e => {
    e.preventDefault();
    if (isEmail === 'email' || isEmail === 'mobile') {
      const { mobile_number, password, login_type } = formData;

      if (isEmail === 'mobile') {
        if (!mobile_number) {
          Alert.error("Mobile Number is required.");
          return false;
        }
      }

      const payload = {
        mobile_number,
        password,
        login_type
      };

      attemp({ ...payload, email: payload.mobile_number }, () => {
        window.location.href = "/";
        setModal({ isOpen: false });
      });
    }
    if (isEmail === 'user_id') {
      const { user_id, login_pin, login_type } = formData;

      const payload = {
        user_id,
        login_pin,
        login_type
      };

      attemp({ ...payload, user_id: payload.user_id }, () => {
        window.location.href = "/";
        setModal({ isOpen: false });
      });
    }

  };

  const handleOpenForgotPasswordModal = e => {
    e.preventDefault();
    setModal({
      isOpen: true,
      content: <ForgotPasswordContainerModal />,
      title: null,
      modalSize: "modal-lg",
      hideHeader: true,
      isCentered: true
    });
  };

  const handleOnClose = e => {
    e.preventDefault();
    clearAttemp();
    setModal({ isOpen: false });
    setFormData({
      password: "",
      profile_type: "taxpayer",
      otp: "",
      login_type: "credentials",
      mobile_number: "",
      disableResendOTP: false,
      user_id: "",
      login_pin: ""
    });
  };

  const handleOnChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleOnChangePin = pin => {
    setFormData({
      ...formData,
      login_pin: pin
    });
  };

  return (
    <Modal.Body className="p-0">
      <div className="login-container">
        <button
          type="button"
          className="close modal-close"
          onClick={handleOnClose}
        >
          <span aria-hidden="true">&times;</span>
        </button>
        <div className="container-fluid">
          <div className="row">
            <div className="form-section col-12 col-md-6 d-flex align-items-center">
              <div className="form-content w-100">
                <img
                  src={_.get(CMS_LOGOS, "MAIN_LOGO.cdnUrl")}
                  alt=""
                  className="icon-logo"
                />
                <h2 className="form-title font-weight-bold mt-4 mb-0 cms-text-primary">
                  Welcome,
                </h2>
                <p className="form-sub-title cms-text-primary">
                  {!_.has(attempData, "is_active") && (
                    <>Login to your account</>
                  )}
                  {_.has(attempData, "is_active") &&
                    `Key in your OTP (One Time Password)`}
                </p>
                <form onSubmit={handleOnSubmit}>
                  <div className="form-wrapper">
                    <div className="full-group">
                      <div className="form-row">
                        <div className="login-div-buttons">
                          {_.get(CMS_COMPONENTS, 'TAXPAYER_USERNAME') && (
                            <button
                              type="button"
                              onClick={() => {
                                setIsEmail("user_id");
                                setFormData({});
                              }}
                              className={`login-div-item ${isEmail === 'user_id' ? 'active' : ''}`}>
                              <span className='login-div-span'>Username</span>
                            </button>
                          )}
                        </div>
                        <div className="login-div-buttons">
                          {_.get(CMS_COMPONENTS, "EMAIL_LOGIN") && (
                            <button
                              type="button"
                              onClick={() => {
                                setIsEmail("email");
                                setFormData({});
                              }}
                              className={`login-div-item ${isEmail === 'email' ? 'active' : ''}`}
                            >
                              <span className='login-div-span'>Email Address</span>
                            </button>
                          )}
                          {(_.get(CMS_COMPONENTS, "SMART_CITY_HEADER") === false || _.get(CMS_COMPONENTS, "SMART_CITY_HEADER") === undefined) && (                            <button
                            type="button"
                            onClick={() => {
                              setIsEmail("mobile");
                              setFormData({});
                            }}
                            className={`login-div-item ${isEmail === 'mobile' ? 'active' : ''}`}>
                            <span className='login-div-span'>Mobile Number</span>
                          </button>
                          )}
                        </div>
                        <div className="form-input-group col-12 mt-2">
                          <label
                            htmlFor="mobile_number"
                            className="full-label mb-0 login-options"
                          >
                            <span>
                              {isEmail === 'email' && ("Email ")}
                              {_.get(CMS_COMPONENTS, 'TAXPAYER_USERNAME') && (isEmail === 'user_id') && ("Username")}
                              {isEmail === 'mobile' && ("Mobile Number ")}
                              {(isEmail === 'email' || isEmail === 'mobile')
                                && (
                                  formValidator.renderMessage('mobile_number')
                                )
                              }
                              {(_.get(CMS_COMPONENTS, 'TAXPAYER_USERNAME') && isEmail === 'user_id' && (formValidatorUserId.renderMessage('user_id')))}
                            </span>
                          </label>

                          {isEmail === 'email' && _.get(CMS_COMPONENTS, "EMAIL_LOGIN") && (
                            <>
                              <input
                                className="full-input"
                                type="email"
                                name="mobile_number"
                                value={_.get(formData, "mobile_number") || ""}
                                autoComplete="off"
                                required
                                onChange={handleOnChange}
                              />
                            </>
                          )}

                          {isEmail === 'mobile' && (
                            <>
                              <FormInputMobile
                                disableDropdown={false}
                                autoComplete="off"
                                type="tel"
                                name="mobile_number"
                                onChange={setFormData}
                                value={_.get(formData, "mobile_number") || ""}
                              />
                            </>
                          )}
                          {_.get(CMS_COMPONENTS, 'TAXPAYER_USERNAME') && isEmail === 'user_id' && (
                            <>
                              <input
                                className="full-input"
                                type="text"
                                name="user_id"
                                value={_.get(formData, "user_id") || ""}
                                autoComplete="off"
                                required
                                onChange={handleOnChange}
                              />
                            </>
                          )}
                        </div>
                        {isEmail === 'email' && (
                          <div className="form-input-group col-12 prepend-form-right">
                            <label htmlFor="name" className="full-label mb-0">
                              Password&nbsp;{formValidator.renderMessage('password')}
                            </label>
                            <input
                              className="full-input"
                              type={view ? "text" : "password"}
                              name="password"
                              value={_.get(formData, "password") || ""}
                              required
                              onChange={handleOnChange}
                            />
                            <button
                              type="button"
                              onClick={handleOnViewPassword(!view)}
                              className="btn btn-default rounded-circle p-0 mr-1"
                            >
                              <img src={`${!view ? eyeClose : eyeOpen}`} alt="" />
                            </button>
                          </div>
                        )}
                        {isEmail === 'mobile' && (
                          <div className="form-input-group col-12 prepend-form-right">
                            <label htmlFor="name" className="full-label mb-0">
                              Password&nbsp;{formValidator.renderMessage('password')}
                            </label>
                            <input
                              className="full-input"
                              type={view ? "text" : "password"}
                              name="password"
                              value={_.get(formData, "password") || ""}
                              required
                              onChange={handleOnChange}
                            />
                            <button
                              type="button"
                              onClick={handleOnViewPassword(!view)}
                              className="btn btn-default rounded-circle p-0 mr-1"
                            >
                              <img src={`${!view ? eyeClose : eyeOpen}`} alt="" />
                            </button>
                          </div>
                        )}
                        {_.get(CMS_COMPONENTS, 'TAXPAYER_USERNAME') && isEmail === 'user_id' && (
                          <div className="form-input-group mt-2 ml-2">
                            <label
                              htmlFor="mobile_number"
                              className="full-label mb-0 login-options"
                            >
                              4-Digit PIN&nbsp;{formValidatorUserId.renderMessage('login_pin')}
                            </label>
                          </div>
                        )}
                      </div>
                      {_.get(CMS_COMPONENTS, 'TAXPAYER_USERNAME') && isEmail === 'user_id' && (
                        <>
                          <div className="form-wrapper">
                            <LoginPinInput onChange={handleOnChangePin} />
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  {isEmail === 'email' && (
                    <>
                      <Link
                        to="/"
                        onClick={handleOpenForgotPasswordModal}
                        className="forgot-password text-right mt-2 d-block cms-text-primary"
                      >
                        Forgot Password
                      </Link>
                      <button
                        type="submit"
                        className="btn btn-primary w-100 mt-4 cms-bg-color-primary cms-border-color-primary"
                        disabled={attempting || !formValidator.response.is_passed}
                      >
                        {attempting && <i className="fa fa-spin fa-spinner" />}{" "}
                        Login
                      </button>
                    </>
                  )}
                  {isEmail === 'mobile' && (
                    <>
                      <Link
                        to="/"
                        onClick={handleOpenForgotPasswordModal}
                        className="forgot-password text-right mt-2 d-block cms-text-primary"
                      >
                        Forgot Password
                      </Link>
                      <button
                        type="submit"
                        className="btn btn-primary w-100 mt-4 cms-bg-color-primary cms-border-color-primary"
                        disabled={attempting || !formValidator.response.is_passed}
                      >
                        {attempting && <i className="fa fa-spin fa-spinner" />}{" "}
                        Login
                      </button>
                    </>
                  )}
                  {_.get(CMS_COMPONENTS, 'TAXPAYER_USERNAME') && isEmail === 'user_id' && (
                    <button
                      type="submit"
                      className="btn btn-primary w-100 mt-4 cms-bg-color-primary cms-border-color-primary"
                      disabled={attempting || !formValidatorUserId.response.is_passed}
                    >
                      {attempting && <i className="fa fa-spin fa-spinner" />}{" "}
                      Login
                    </button>
                  )}

                  <Link
                    to="/"
                    onClick={handleOpenAuthModal}
                    className="sign-up mt-3 d-block cms-text-primary"
                  >
                    Don’t have account yet? <strong>Sign up here.</strong>
                  </Link>
                </form>
              </div>
            </div>
            <div className="info-section col-12 col-md-6 d-flex align-items-center">
              <div className="info-container">
                <h1 className="info-title font-weight-bold text-light">
                  {_.get(CMS_VARIABLES, "ORGANIZATION")} Local Government System
                </h1>
                {(_.get(CMS_COMPONENTS, "SMART_CITY_HEADER") === false || _.get(CMS_COMPONENTS, "SMART_CITY_HEADER") === undefined) && (
                  serviceList &&
                  serviceList.filter(x => ['E-Government', 'E-Bills', 'E-Commerce', 'E-Services'].indexOf(_.get(x, 'attributes.name')) > -1).map(item => (
                    <div key={item.id} className="row mt-4">
                      <div className="col-3 pr-0">
                        <img
                          src={_.get(item, "attributes.photo")}
                          className="img-responsive"
                          alt="e-gov"
                        />
                      </div>
                      <div className="col-9 mt-4">
                        <h5 className="info-service-title">
                          {_.get(item, "attributes.name")}
                        </h5>
                        <p className="info-service-desc">
                          {_.get(item, "attributes.description")}
                        </p>
                      </div>
                    </div>
                  )))}
                {_.get(CMS_COMPONENTS, "SMART_CITY_HEADER") === true && (
                  serviceList &&
                  serviceList.map(item => (
                    <div key={item.id} className="row mt-4">
                      <div className="col-3 pr-0">
                        <img
                          src={_.get(item, "attributes.photo")}
                          className="img-responsive"
                          alt="e-gov"
                        />
                      </div>
                      <div className="col-9 mt-4">
                        <h5 className="info-service-title">
                          {_.get(item, "attributes.name")}
                        </h5>
                        <p className="info-service-desc">
                          {_.get(item, "attributes.description")}
                        </p>
                      </div>
                    </div>
                  )))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal.Body>
  );
}

LoginContainer.propTypes = {
  // history: PropTypes.instanceOf(Object).isRequired,
  attemp: PropTypes.instanceOf(Function).isRequired,
  attempting: PropTypes.bool.isRequired,
  attempData: PropTypes.instanceOf(Object).isRequired,
  clearAttemp: PropTypes.instanceOf(Function).isRequired,
  serviceList: PropTypes.instanceOf(Array).isRequired,
};

const removeENews = res => {
  const newArr = res.filter(d => {
    return (
      d.attributes.name !== "E-Inquiry" &&
      d.attributes.name !== "E-Wallet" &&
      d.attributes.name !== "E-Help" &&
      d.attributes.name !== "E-Government and E-Services"
    );
  });
  return newArr;
};

const mapStateToProps = ({ api }) => ({
  attempting: _.get(api, "loading", []).indexOf(c.ATTEMP) > -1,
  attempData: _.get(api, `${c.ATTEMP}.item`) || {},
  serviceList: removeENews(_.get(api, "SERVICES/get_services", []).list || [])
});

const enhance = _.flowRight([withRouter, connect(mapStateToProps, actions)]);

export default enhance(LoginContainer);
