import React, { useContext, useState } from "react";
import { withRouter, Link } from "react-router-dom";
import _ from "lodash";
import { connect } from "react-redux";
import { ModalContext } from "App";
import Modal from "modules/common/components/Modal";
import eyeOpen from "assets/img/open-eye.png";
import eyeClose from "assets/img/close-eye.png";
// import SignUpContainerModal from "modules/auth/containers/SignUpContainerModal";
import PropTypes from "prop-types";
import * as actions from "../actions";
import * as c from "../constants";
import ForgotPasswordContainerModal from "./ForgotPasswordContainerModal";
import CmsContext from "../../../CmsContext";

const form = {
  // email: 'taxpayer@email.com',
  // password: '123123123',
  email: "",
  password: "",
  profile_type: "taxpayer"
};

function ResetPasswordContainer({ resetPassword, isLoading, history,
  serviceList }) {
  const [formData, setFormData] = useState(form);
  const setModal = useContext(ModalContext);
  const [view, setView] = React.useState(false);
  const [viewConfirm, setViewConfirm] = React.useState(false);

  const cmsContext = useContext(CmsContext);
  const CMS_LOGOS = cmsContext.findByType("LOGOS");
  const CMS_VARIABLES = cmsContext.findByType("VARIABLES");

  const handleOnViewPassword = value => e => {
    e.preventDefault();
    setView(value);
  };

  const handleOnViewConfirmPassword = value => e => {
    e.preventDefault();
    setViewConfirm(value);
  };

  // const handleOpenAuthModal = (e) => {
  //   e.preventDefault();
  //   history.push("/");
  //   setModal({ isOpen: false });
  //   setModal({
  //     isOpen: true,
  //     content: <SignUpContainerModal />,
  //     title: null,
  //     modalSize: "modal-lg",
  //     hideHeader: true,
  //     isCentered: true,
  //   });
  // };

  const handleOpenForgotPasswordModal = e => {
    e.preventDefault();
    history.push("/");
    setModal({ isOpen: false });
    setModal({
      isOpen: true,
      content: <ForgotPasswordContainerModal />,
      title: null,
      modalSize: "modal-lg",
      hideHeader: true,
      isCentered: true
    });
  };

  const handleOnSubmit = e => {
    e.preventDefault();
    const urlParams = new URLSearchParams(window.location.search);
    resetPassword(
      {
        ...formData,
        reset_token: urlParams.get("token"),
        email: urlParams.get("email")
      },
      () => {
        history.push("/");
        setModal({ isOpen: false });
      }
    );
  };

  const handleOnClose = e => {
    e.preventDefault();
    history.push("/");
    setModal({ isOpen: false });
  };

  const handleOnChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  return (
    <Modal.Body className="p-0">
      <div className="login-container">
        <button
          type="button"
          className="close modal-close"
          onClick={handleOnClose}
        >
          <span aria-hidden="true">&times;</span>
        </button>
        <div className="container-fluid">
          <div className="row">
            <div className="form-section col-12 col-md-6 d-flex align-items-center">
              <div className="form-content w-100">
                <img src={_.get(CMS_LOGOS, "MAIN_LOGO.cdnUrl")} alt="" className="icon-logo" />
                <div className="mt-5">
                  <span className="label-modal font-weight-bold"> Create New Password</span>
                </div>
                <form onSubmit={handleOnSubmit}>
                  <div className="form-wrapper mt-4">
                    <div className="full-group">
                      <div className="form-row">
                        <div className="form-input-group col-12 col-md-6 prepend-form-right">
                          <label htmlFor="name" className="mb-0 password-label">
                            New Password
                          </label>
                          <input
                            className="full-input"
                            type={view ? "text" : "password"}
                            name="password"
                            value={_.get(formData, "password") || ""}
                            required
                            onChange={handleOnChange}
                          />
                          <button
                            type="button"
                            onClick={handleOnViewPassword(!view)}
                            className="btn btn-default rounded-circle p-0 mr-1"
                          >
                            <img src={`${!view ? eyeClose : eyeOpen}`} alt="" />
                          </button>
                        </div>
                        <div className="form-input-group col-12 col-md-6 prepend-form-right">
                          <label htmlFor="name" className="mb-0 password-label">
                            Confirm New Password
                          </label>
                          <input
                            className="full-input"
                            type={viewConfirm ? "text" : "password"}
                            name="password_confirmation"
                            value={
                              _.get(formData, "password_confirmation") || ""
                            }
                            required
                            onChange={handleOnChange}
                          />
                          <button
                            type="button"
                            onClick={handleOnViewConfirmPassword(!viewConfirm)}
                            className="btn btn-default rounded-circle p-0 mr-1"
                          >
                            <img
                              src={`${!viewConfirm ? eyeClose : eyeOpen}`}
                              alt=""
                            />
                          </button>
                        </div>
                      </div>
                      <div className="mt-3 p-3 password-background" role="alert">
                        <ul className="mb-0">
                          <li>Minimum of 8 Characters</li>
                          <li>At least one uppercase and lowercase letter</li>
                          <li>At least one symbol</li>
                          <li>At least one number</li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <Link
                    to="/"
                    onClick={handleOpenForgotPasswordModal}
                    className="forgot-password text-right mt-2 d-block"
                  >
                    Forgot Password
                  </Link>
                  <button
                    type="submit"
                    className="btn btn-primary w-100 mt-4 cms-bg-color-primary"
                    disabled={isLoading}
                  >
                    {isLoading && <i className="fa fa-spin fa-spinner" />} Save
                  </button>
                  {/* <Link
                    to='/'
                    onClick={handleOpenAuthModal}
                    className='sign-up mt-3 d-block'>
                    Don’t have account yet? <strong>Sign up here.</strong>
                  </Link> */}
                </form>
              </div>
            </div>
            <div className="info-section col-12 col-md-6 d-flex align-items-center">
              <div className="info-container">
                <h1 className="info-title font-weight-bold text-light">
                  {_.get(CMS_VARIABLES, "ORGANIZATION")} Local Government System
                </h1>
                {serviceList &&
                  serviceList.filter(x => ['E-Government', 'E-Bills', 'E-Commerce', 'E-Services'].indexOf(_.get(x, 'attributes.name')) > -1).map(item => (
                    <div className="row mt-4">
                      <div className="col-3 pr-0">
                        <img
                          src={_.get(item, "attributes.photo")}
                          className="img-responsive"
                          alt="e-gov"
                        />
                      </div>
                      <div className="col-9 mt-4">
                        <h5 className="info-service-title">
                          {_.get(item, "attributes.name")}
                        </h5>
                        <p className="info-service-desc">
                          {_.get(item, "attributes.description")}
                        </p>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal.Body>
  );
}

ResetPasswordContainer.propTypes = {
  resetPassword: PropTypes.instanceOf(Function).isRequired,
  isLoading: PropTypes.bool.isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
  serviceList: PropTypes.instanceOf(Array).isRequired
};


const removeENews = res => {
  const newArr = res.filter(d => {
    return d.attributes.name !== "E-News" && d.attributes.name !== "E-Wallet";
  });
  return newArr;
};

const mapStateToProps = ({ api }) => ({
  isLoading: _.get(api, "loading", []).indexOf(c.LOGIN) > -1,
  serviceList: removeENews(_.get(api, "SERVICES/get_services", []).list || [])
});

const enhance = _.flowRight([withRouter, connect(mapStateToProps, actions)]);

export default enhance(ResetPasswordContainer);
